.icon-social svg,
.icon-social svg:visited {
  height: 40px;
  width: 40px;
  fill: var(--linkColor);
  margin: 0 1em 0 0;
}

.icon-social a, .icon-social a:hover, .icon-social a:active {
    outline: none;
    background: transparent;
    text-decoration: none;
}

.icon-social svg:hover,
.icon-social svg:active {
  fill: var(--linkColorHover);
  background-color: var(--linkBgHover);
  outline: var(--linkBgHover) solid 10px;
  border-radius: 10%;
}

.icon-social {
    &--large {
        text-align: center;
        display: flex;
        justify-content: space-around;
        margin-bottom: 80px;

        & svg, & svg:visited {
            height: 80px;
            width: 80px;
            margin: 0;
        }
        &.wrapper::after {
            content: none;
        }
    }
}

