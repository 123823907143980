@charset "utf-8";

// Define defaults for each variable.

$base-font-family: Inter, serif;
$base-font-size: 18px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #35373a !default;
$background-color: #fdfdfd !default;
$brand-color: #4f27e5 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

// Color themes
// @see https://codepen.io/2kool2/pen/abzgPzJ
body {
    --color: hsl(269, 19%, 30%);
    --bgColor: rgb(255, 255, 255);
    --linkColor: hsla(214, 71%, 47%, 1);
    --linkColorHover: hsla(214, 100%, 35%, 1);
    --linkBgHover: hsla(214, 100%, 85%, 1);
    --linkFocus: hsla(214, 71%, 80%, 0.3);
    --focusOutline: var(--linkFocus) solid 0.25rem;
    --greyColorLight: #e0e0ef;
    --imgFilter: none;
    --bgPage: none;
    --primaryFont: $base-font-family;
    --secondaryFont: basic-sans, sans-serif;
    --wideWidth: 860px;
}

@media (prefers-color-scheme: dark) {
  body:not([data-lightMode="light"]) {
    --color: #fafafa;
    --bgColor: hsl(223, 11%, 12%);
    --linkColor: hsla(214, 100%, 80%, 1);
    --linkColorHover: hsla(214, 100%, 11%, 1);
    --linkFocus: hsla(214, 100%, 80%, .5);
    --focusOutline: hsla(214, 100%, 85%, .6) solid .25rem;
    --greyColorLight: #303020;
    --imgFilter: grayscale(15%) brightness(85%);
    --bgPage: none;
  }
}

$table-text-align: left !default;

$mobile: 375px;
$portrait: 768px;
$landscape: 1024px;
$desktop: 1440px;
$wide: 1920px;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

// Use media queries like this:
// @include on($desktop) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin on($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin dark() {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import "minima/base", "minima/layout", "minima/syntax-highlighting", "article", "icons",
  "homepage", "photoindex", "postlist", "gallery", "blogindex", "exhibit";
