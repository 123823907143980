/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height}
    $base-font-family;
  color: var(--color);
  background-color: var(--bgColor);
  background-image: var(--bgPage);
  transition: background-image 0.3s ease-out, background-color 0.3s ease-out,
    color 0.3s ease-out;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @include on($portrait) {
    /* font-size: 28px; */
  }
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit;
}

/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}

/**
 * Images
 */
img:not([src*=".svg"]) {
  filter: var(--imgFilter);
  transition: filter 0.3s ease-out;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
  width: auto;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

/**
 * Lists
 */
ul,
ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--secondaryFont);
}

/**
 * Links
 */
a {
  text-decoration: none;

  &,
  &:link,
  &:visited {
    color: var(--linkColor);
    text-decoration-color: var(--linkColorUnderline);
    outline: hsla(214, 71%, 80%, 0) solid 0.25rem;
    transition: color 0.3s ease-out, background-color 0.3s ease-out,
      outline-color 0.3s ease-out;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

/**
 * Blockquotes
 */
blockquote {
  p {
    color: $grey-color;
    border-left: 8px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    @include relative-font-size(1.125);
    font-style: italic;
    > :last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.7);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
  @include dark() {
    border-color: #333344;
    background-color: #444455;
  }
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin wrapper() {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
  max-width: calc(#{$content-width} - (#{$spacing-unit}));
  padding-right: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
  margin-right: auto;
  margin-left: auto;
  @extend %clearfix;

  @include on($portrait) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }
}

/**
 * Wrapper
 */
.wrapper {
  @include wrapper();
}

/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

/**
 * Icons
 */

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #{$grey-color};
  padding-right: 5px;
  vertical-align: text-top;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}

/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th,
  td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}

.cta {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    padding: 0.5em 1em;
    font-size: 20px;
    background-color: #22009c;
    color: white;
    border: white;
    border-radius: 0.3em;

    &:hover {
        background-color: #3f00ff;
        cursor: pointer;
    }
}

a.cta {
    width: auto;
    @include on($portrait) {
        width: fit-content;
    }
}

button.cta {
    width: 100%;
    @include on($portrait) {
        width: auto;
    }
}

.cta .icon-image, .cta .icon-text {
    display: block;
    flex-grow: 0;
}

.cta .icon-text {
    text-align: center;
    flex-grow: 1;
}

.cta .icon-image {
    fill: #FFFFFF;
    margin-right: 0.5em;
    svg {
        width: 2em;
    }
}

