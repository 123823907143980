.blogindex {
  &.wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  &__header, h2 {
    max-width: var(--wideWidth);
    margin: 0 auto;
    padding-left: $spacing-unit / 2;
    padding-right: $spacing-unit / 2;
    @include on($portrait) {
      padding-left: $spacing-unit;
      padding-right: $spacing-unit;
    }
  }

  h2 {
      padding-top: $spacing-unit;
      padding-bottom: $spacing-unit;
  }
}
