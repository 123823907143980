.photoindex {
    &__hero {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        max-height: none;
    }

    &__hero-picture {
        object-position: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -10;
        filter: brightness(50%) !important;
        -webkit-filter: brightness(50%);
    }

    &__hero-copy {
        @include wrapper();
        color: #aaaaaa;
    }

    &__hero-signature {
        max-width: 400px;
    }

    .album-feed {
        padding: 4vmin;
        display: grid;
        grid: auto / repeat(1, 1fr);
        gap: 4vmin;

        @include on($portrait) {
            grid: auto / repeat(6, 1fr);
        }

        &__card {
            grid-column: span 3;
            display: grid;
            overflow: hidden;
        }

        &__card:nth-child(5n),
        &__card:nth-child(5n-1),
        &__card:nth-child(5n-2) {
            grid-column: span 2;
        }

        &__card-image-link,
        &__card-content {
            grid-column: 1/-1;
            grid-row: 1/-1;
        }

        &__link,
        &__details {
            grid-column: 1/-1;
            grid-row: 1/-1;
        }

        &__picture {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &__details {
            z-index: 1;
            background: linear-gradient(to bottom, rgba(0, 0, 0, .6), black);
            padding: 0 0 10px;
            align-self: end;
            text-align: center;

            @include on($portrait) {
                margin-bottom: -10vmin;
                transition: margin-bottom 0.1s ease-out;
            }

            h3 {
                margin: 0;
                a {
                    font-size: 20px;
                }
            }
        }

        @include on($portrait) {
            .album-feed__card:hover .album-feed__details {
                margin-bottom: 0;
            }
        }
    }
}
