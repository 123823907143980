article {
  .post {
    img:not([src*=".svg"]) {
      filter: var(--imgFilter);
      transition: filter 0.3s ease-out;
    }

    &__header {
      &--portrait {
        display: flex;
        flex-direction: column-reverse;
        @include on($portrait) {
          flex-direction: row;
        }
        .page-heading {
          margin: auto;
          padding: $spacing-unit 0;
          @include on($portrait) {
            padding: 0;
          }
        }
      }
    }

    &__heading {
      align-self: center;
    }

    &__cover {
      flex: 0 0 50%;
      order: -1;
      img {
        width: 100%;
        height: auto;
        margin-bottom: $spacing-unit;
        @include on($portrait) {
            margin-bottom: $spacing-unit * 3;
        }
      }
    }

    &__title {
      font-size: 200%;
      max-width: inherit;
      padding-left: $spacing-unit / 2;
      padding-right: $spacing-unit / 2;
      text-align: center;
      @include on($portrait) {
        font-size: 250%;
      }
    }

    &__meta {
      font-family: var(--secondaryFont);
      font-size: 80%;
      color: $grey-color;
      padding: $spacing-unit / 2 $spacing-unit 0;
      margin-bottom: 0;
      text-align: center;
      line-height: 1.4;
    }
  }
  /**
  * Posts
  */

  .post-content {
    margin-bottom: $spacing-unit;

    h2,
    h3,
    h4 {
      @include wrapper();
      text-align: center;
    }

    h2 {
      padding-top: $spacing-unit;
      border-top: 1px solid var(--greyColorLight);
      margin-left: $spacing-unit / 2;
      margin-right: $spacing-unit / 2;
      @include on($portrait) {
        margin-left: auto;
        margin-right: auto;
      }
    }

    h3 {
      padding-top: $spacing-unit * 2;
    }

    & > picture {
      @include wrapper();
      display: block;
      padding: $spacing-unit / 2 0 $spacing-unit;
    }
  }

  a {
    &:focus,
    &:hover {
      color: var(--linkColorHover);
      outline: var(--linkBgHover) solid 0.25rem;
      background-color: var(--linkBgHover);
      text-decoration: underline;
    }
  }

  p,
  ul,
  ol,
  div.highlighter-rouge {
    @include wrapper();
    /* line-height: 1.3; */
  }

  li {
    margin-left: $spacing-unit;
    @include on($portrait) {
        margin-left: $spacing-unit * 1.5;
    }
  }
}
