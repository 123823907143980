section.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: $spacing-unit 0;
    @include on($portrait) {
        padding: $spacing-unit * 2 0 $spacing-unit;
    }
    picture.photo-picture {
        width: 100%;
        @include on($landscape) {
            max-width: 45%;
        }
        margin-bottom: 3%;
        // cursor: pointer;

        &.max-width-inherit {
            max-width: inherit;
            width: 100%;
        }
        
        img {
            width: 100%;
            height: auto;
        }
    }
}
