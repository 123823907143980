/**
 * Site header
 */
.site-header {
  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

.site-nav {
  font-family: var(--secondaryFont);
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;
  position: fixed;
  bottom: 20px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: right;
  fill: var(--linkColor);
  color: var(--color);
  font-size: 120%;
  z-index: 10;
  opacity: 0;
  transition: opacity 1s;

  @media (prefers-color-scheme: dark) {
    background-color: #333;
    border-color: #444;
    fill: var(--linkColor);
    color: var(--color);
  }

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: inherit;
    line-height: $base-line-height;
    text-decoration: none;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }

    &--tag {
        font-size: 80%;
    }
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  .menu-icon {
    display: block;
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    display: block;
    padding: 10px 20px;

    @include on($portrait) {
        padding: 5px 10px;
    }

    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
  }

  &--home {
    right: 20px;
    opacity: 0.5;
  }

  &--scroll {
    right: 70px;
    opacity: 0.5;
  }

  &.topside {
    opacity: 1;
  }

  &--scroll.topside {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid var(--greyColorLight);
  padding: $spacing-unit 0;
  margin-top: $spacing-unit * 6;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

/**
 * Page content
 */
.page-content {
  flex: 1;
}

.page-heading {
  padding: $spacing-unit * 2 0;
  line-height: 1;
  @include on($portrait) {
    padding: $spacing-unit * 4 0;
    line-height: inherit;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .home & {
    text-align: center;
    @include on($portrait) {
        padding: $spacing-unit * 2 0;
    }
  }
}

.page-description {
    margin-bottom: $spacing-unit * 2;
    font-size: 120%;
    font-family: var(--secondaryFont);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}
