.exhibit {
    display: flex;
    flex-wrap: wrap;
    &__slide {
        background-color: #222;
        width: 100vw;
        height: 100vh;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &--active {
            cursor: pointer;
        }
    }
    &__intro {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.6);
        color: #f0f0f0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
            font-size: 250%;
        }
        & > * {
            max-width: 45rem;
        }
    }
    &__instructions {
        position: absolute;
        bottom: 0;
        opacity: 0.5;
    }
    &__cover {
        width: 100%;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__outro {
        display: flex;
        width: 80%;
        margin: 10% auto;
        color: #f0f0f0;
        flex-direction: column;
        @include on($portrait) {
            flex-direction: row;
        }
        img {
            max-width: 50%;
        }
    }
    &__credits {
        padding: 2rem 0;
        @include on($portrait) {
            padding: 0 2rem;
        }
        ul {
            opacity: 0.7;
            margin: 0;
            padding: 0;
            line-height: 1.5;
            margin-bottom: 1.8rem;
        }
        li {
            list-style: none;
        }
    }
}
