/* Migrate these... */

.fronthero {
  display: flex;
  flex-direction: column;

  @include on($landscape) {
    flex-direction: row;
  }

  &__image {
    flex: 0 0 50%;
    overflow: hidden;
  }

  &__cover {
    min-width: auto;
    width: auto;
    height: auto;
    height: 65vh;

    width: 100%;
    max-width: none;
    object-fit: cover;
    object-position: 36%;

    @include on($landscape) {
      width: 100% !important;
      max-width: 100%;
      min-width: 50%;
      height: 100vh;
    }

    // @include on($landscape) {
    //   object-position: 60%;
    // }
  }

  &__content {
    padding: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        margin-bottom: 0;
        letter-spacing: 0;
        line-height: 1.2;
    }

    a, a:visited, a:active {
        color: white;
    }
  }

  &__copy {
    /* Show the frontpage copy proportional to the screen size */
    @include on($landscape) {
        font-size: 1.3vw;
    }
    @include on($wide) {
        font-size: 1.8rem;
    }
    a, button {
        @include on($landscape) {
            font-size: 1em;
        }
    }
    /* make the first paragraph stand out */
    p:first-of-type {
      font-weight: 600;
      margin-bottom: 2em;
      line-height: 1.2;
      @include on($landscape) {
        line-height: 1;
      }
    }
  }
}

@-webkit-keyframes arrow-jump {
  0% {
    opacity: 0.4;
    transform: translateY(0);
  }
  50% {
    opacity: 0.6;
    transform: translateY(10px);
  }
  100% {
    opacity: 0.4;
    transform: translateY(0);
  }
}

.arrow {
  animation: arrow-jump 3s infinite; /* IE 10+, Fx 29+ */
}
