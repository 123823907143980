.postlist {
  &__item {
    display: grid;
    grid-template:
      "heading" 0.1fr
      "meta" 0.1fr
      "excerpt" 1fr / 1fr;
    padding: 0 $spacing-unit / 2 $spacing-unit * 2;
    /*
    &--has-cover {
      grid-template:
        "heading" auto
        "image-link" auto
        "meta" auto
        "excerpt" auto / auto;

      @include on($portrait) {
        grid-template:
          "heading image-link" 0.1fr
          "meta image-link" 0.1fr
          "excerpt image-link" 1fr / auto 390px;
      }
    }
    */

    @include on($portrait) {
      padding: $spacing-unit;
      margin: 0 auto $spacing-unit;
      max-width: var(--wideWidth); // cover + padding + copy
    }
  }

  &__heading {
    grid-area: heading;
    margin-bottom: $spacing-unit / 2;
    @include on($portrait) {
      margin-bottom: 0;
    }
  }

  &__meta {
    font-family: var(--secondaryFont);
    font-size: 80%;
    grid-area: meta;
    margin-bottom: $spacing-unit / 2;
  }

  &__image-link {
    grid-area: image-link;
    @include on($portrait) {
      max-width: 360px;
    }
  }

  &__cover {
    grid-area: cover;
    max-width: inherit;
    padding: 0;
    filter: brightness(1);
    overflow: hidden;
    width: 100%;
    @include dark() {
      filter: brightness(0.9);
      opacity: 0.9;
    }
    img {
      padding-bottom: $spacing-unit / 2;
      @include on($portrait) {
        padding-bottom: $spacing-unit / 2;
      }
    }
  }

  &__excerpt {
    grid-area: excerpt;
    padding-right: $spacing-unit;
    max-width: 800px;
  }
}
